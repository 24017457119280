.speaker-icon{
    color: var(--announcement-color);
}

/* First path (no delay, immediate animation) */
.disappearing-path:first-of-type {
    animation: disappearTogether 3s linear infinite;
    animation-delay: 0s;
  }
  
  /* Second path (delayed appearance/disappearance) */
  .disappearing-path:nth-of-type(2) {
    animation: appearDisappear 2s linear infinite;
    animation-delay: 1s;
  }
  
  /* Third path (longer delay) */
  .disappearing-path:nth-of-type(3) {
    animation: disappearTogether 2s linear infinite;
    animation-delay: 1s;
  }
  
  /* Keyframes for appearing and disappearing (without fade) */
  @keyframes appearDisappear {
    0%, 49% {
      visibility: hidden; /* Hidden for the first half */
    }
    50%, 100% {
      visibility: visible; /* Visible in the second half */
    }
  }
  
  /* Keyframes for synchronized disappearance */
  @keyframes disappearTogether {
    0%, 69% {
      visibility: hidden; /* Hidden until 70% of the animation */
    }
    70%, 100% {
      visibility: visible; /* Visible until the end */
    }
  }