#spin-wheel {
    position: fixed;
    right: 50px;
    bottom: 150px;
    transform: translate(50%, 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    cursor: pointer;
  }

  @media only screen and (max-width: 768px) {
    #spin-wheel {
      right: 50px;
      bottom: 200px;
    }
  }

  .wheel-icon {
    width: 80px;
    height: 80px;
    /* animation: rotate 10s linear infinite; */
  }

  .wheel-icon svg {
    width: 100%;
  }

  .spin-wheel-close {
    width:15px;
    height:15px;
    color: white;
    text-align: center;
    position: absolute;
    top: 1px;
    right: 5px;
    transform: translate(0, 0);
  }

  .spin-wheel-label{
   background: var(--spin-wheel-time-label);
   padding: 3px 8px;
   border-radius: 10px;
   color: var(--base-color);
   text-align: center;
   font-weight:500;
   font-size:10px;
   position:absolute;
   bottom: 1%;
   left: 50%;
   transform: translate(-50%, -50%);
   white-space: nowrap;
  }

  #spin-wheel-modal iframe {
    width: 100%;
    min-height: 700px;
    height: 100vh;
    border: 0;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }