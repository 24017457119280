#banner-apk {
  position: fixed;
  left: 0;
  bottom: 50px;
  transform: translate(0, 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  cursor: pointer;
}

.banner-apk-icon {
  width: 485px;
  height: 95px;
}

@media only screen and (max-width: 768px) {
  #banner-apk  {
    /* right: 0; */
    bottom: 100px;
    /* left:auto; */
  }
  .banner-apk-icon {
    width: 300px;
    height: 60px;
  }
}

.banner-apk-icon svg {
  width: 100%;
}

.banner-apk-close {
  width:15px;
  height:15px;
  color: white;
  text-align: center;
  position: absolute;
  top: 1px;
  right: 5px;
  transform: translate(0, 0);
}

@media (max-width: 900px) {
  #banner-apk {
    bottom: 110px;
  }
}
